html,
body {
  position: relative;
  height: 100%;
  min-height: 100vh;
  font-family: 'Source Sans Pro', 'Open Sans', 'Helvetica Neue', sans-serif;
  color: black;
  background-color: white;
  z-index: -1;
}

.zoom:hover {
  transform: scale(1.2);
}

.cursive {
  font-family: 'Salsa', cursive;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (min-width: 640px) {
  [class*='sm:w-3/10'] {
    width: 30% !important;
  }
}

@media (min-width: 768px) {
  [class*='sm:w-3/10'] {
    width: 30% !important;
  }

  [class*='md:w-3/10'] {
    width: 30% !important;
  }
}

@media (min-width: 1024px) {
  [class*='sm:w-3/10'] {
    width: 30% !important;
  }

  [class*='md:w-3/10'] {
    width: 30% !important;
  }

  [class*='lg:w-3/10'] {
    width: 30% !important;
  }
}

@media (min-width: 1280px) {
  [class*='sm:w-3/10'] {
    width: 30% !important;
  }

  [class*='md:w-3/10'] {
    width: 30% !important;
  }

  [class*='lg:w-3/10'] {
    width: 30% !important;
  }

  [class*='xl:w-3/10'] {
    width: 30% !important;
  }
}

@keyframes LYCJl {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes bounce {
  from,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
